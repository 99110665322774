var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'atom-bullet-specs': true, active: _vm.isActive },style:({
    top: _vm.topPostion,
    right: _vm.rightPostion,
    bottom: _vm.bottomPostion,
    left: _vm.leftPostion
  }),attrs:{"id":`${_vm.id}`},on:{"click":_vm.handleClick}},[_c('span',{staticClass:"bullet"}),_vm._v(" "),_c('AtomsCommonAtomIcon',{attrs:{"color":"#fff","size":16,"icon":"plus"}}),_vm._v(" "),_c('AtomsCommonAtomIcon',{attrs:{"color":"#fff","size":30,"icon":"minus"}}),_vm._v(" "),_c('div',{staticClass:"hidden-element",style:({
      top: _vm.showTop,
      right: _vm.showRight,
      bottom: _vm.showBottom,
      left: _vm.showLeft,
      transform: _vm.showCenter
    })},[(_vm.image)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.resourceUrl(_vm.image),"alt":_vm.category}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content-info"},[_c('p',[_vm._v(_vm._s(_vm.category))]),_vm._v(" "),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.text)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }